<template>
    <nav aria-label="Navegar entre as páginas" class="paginador" v-if="page">
        <div>
            Mostrando resultados {{first}} a {{last}} de um total de
            <strong>{{count}}</strong>.
        </div>
        <ul class="pagination justify-content-center mb-0">
            <li class="page-item" :class="{ 'disabled': page === 1 || disabled }">
                <a @click.prevent="$emit('paginate', 1)" class="page-link" href="#">&lt;&lt;</a>
            </li>
            <li class="page-item" :class="{ 'disabled': page === 1 || disabled }">
                <a @click.prevent="$emit('paginate', page - 1)" class="page-link" href="#">&lt;</a>
            </li>
            <li
                class="page-item"
                v-for="p in realPages"
                :key="p"
                :class="{ 'active': page === p, 'disabled': (disabled && page !== p) || p === '...' }"
            >
                <span class="page-link" v-if="p === '...'">{{p}}</span>
                <a @click.prevent="$emit('paginate', p)" class="page-link" href="#" v-else>{{p}}</a>
            </li>
            <li class="page-item" :class="{ 'disabled': page === pages || disabled }">
                <a @click.prevent="$emit('paginate', page + 1)" class="page-link" href="#">&gt;</a>
            </li>
            <li class="page-item" :class="{ 'disabled': page === pages || disabled }">
                <a @click.prevent="$emit('paginate', pages)" class="page-link" href="#">&gt;&gt;</a>
            </li>
        </ul>
    </nav>
    <nav aria-label="Navegar entre as páginas" class="paginador" v-else>
        <div>Buscando resultados...</div>
        <ul class="pagination justify-content-center mb-0">
            <li class="page-item disabled">
                <span class="page-link">&lt;&lt;</span>
            </li>
            <li class="page-item disabled">
                <span class="page-link">&lt;</span>
            </li>
            <li class="page-item disabled">
                <span class="page-link">...</span>
            </li>
            <li class="page-item disabled">
                <span class="page-link">&gt;</span>
            </li>
            <li class="page-item disabled">
                <span class="page-link">&gt;&gt;</span>
            </li>
        </ul>
    </nav>
</template>

<style scoped>
.paginador {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
@media screen and (max-width: 790px) {
    .pagination .page-link {
        padding: 0.25rem 0.5rem;
        line-height: 1.5;
    }
    .paginador {
        text-align: center;
        font-size: 0.875rem;
        display: block !important;
    }
}
</style>

<script>
export default {
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
        count: {
            type: Number,
            default: 0,
        },
        first: {
            type: Number,
            default: 0,
        },
        last: {
            type: Number,
            default: 0,
        },
        page: {
            type: Number,
            default: 0,
        },
        pages: {
            type: Number,
            default: 0,
        },
        max: {
            type: Number,
            default: 6,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        realPages () {
            let max = this.max + this.max%2; // não deixa ímpar, arredonda pro próximo par
            if (this.pages <= max) return Array(this.pages).fill(0).map((_, i) => i+1);
            let arr = [];
            if (this.page < max/2 || this.page > this.pages - max/2) // início, não acompanha página atual
                for (let i = 0; i < max/2; i++)
                    arr.push(i+1);
            else // meio, acompanha página atual, exceto caso seja a última página antes do ... (é o parêntese estranho)
                for (let i = max/2; i > 0; i--)
                    arr.push(this.page - i + 2 - +(this.page === this.pages - max/2));
            arr.push('...');
            for (let i = max/2; i > 0; i--) // fim
                arr.push(this.pages - i + 1);
            return arr;
        },
    }
}
</script>
